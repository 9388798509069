<template>
  <div class="row row-deck row-cards">
    <div
      v-for="item, index in items"
      :key="index"
      class="col-4"
    >
      <MoleculeCard
        :loading="loading"
        body-class="p-2 text-center"
      >
        <div
          class="h1 mt-3"
          :class="item.class"
          v-text="item.value"
        />
        <div class="text-muted mb-3">{{ item.caption }}</div>
      </MoleculeCard>
    </div>
    <div class="col-12">
      <MoleculeCard :loading="loading">
        <div class="d-flex align-items-center">
          <h3 class="card-title">{{ $t('client.many.title') }}</h3>
          <div class="ms-auto lh-1">
            <AtomSelect
              v-model="selectedDays"
              :options="days"
              @input="getClientsCharts()"
            />
          </div>
        </div>
        <apexchart
          type="area"
          height="240"
          class="chart-lg"
          :options="chartOptions"
          :series="series"
        />
      </MoleculeCard>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import MoleculeCard from '@/components/MoleculeCard'
import AtomSelect from '@/components/AtomSelect'

export default {
  name: 'OrganismClientsAnalytics',

  components: {
    MoleculeCard,
    AtomSelect,
  },

  data: () => ({
    chartOptions: {
      chart: {
        parentHeightOffset: 0,
        toolbar: {
          show: false,
        },
        defaultLocale: 'en',
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        padding: {
          top: -20,
          right: 0,
          left: -4,
          bottom: -4,
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        max: max => max || 7,
        labels: {
          formatter: val => val.toFixed(0)
        },
      },
      xaxis: {
        labels: {
          padding: 0,
        },
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: false,
        },
        type: 'datetime',
      },
      labels: [],
      stroke: {
        width: 2,
      },
    },
    series: [{
      data: [0, 0, 0, 0, 0, 0, 0],
    }],
    loading: true,
    selectedDays: 7,
  }),

  computed: {
    ...mapGetters(['clients', 'activeClients', 'inactiveClients']),

    days () {
      return [7, 30, 90].map(value => ({ value, label: this.$t(`days.last.${value}`) }))
    },

    items () {
      return [
        { value: this.clients.length, caption: this.$t('client.total.title') },
        { value: this.activeClients.length, caption: this.$t('client.total.active'), class: 'text-green' },
        { value: this.inactiveClients.length, caption: this.$t('client.total.inactive'), class: 'text-red' },
      ]
    },
  },

  async mounted () {
    try {
      await this.getClientsCharts()
    } finally {
      this.loading = false
    }
  },

  methods: {
    ...mapActions(['getClientsChart']),

    async getClientsCharts () {
      try {
        this.loading = true
        const result = await this.getClientsChart(this.selectedDays)
        this.series = [{
          name: this.$t('client.many.title'),
          data: result.data,
        }]
        this.chartOptions = {
          ...this.chartOptions,
          labels: result.labels,
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
