<template>
  <div>
    <MoleculeCard
      v-if="isStaff"
      title="Sales funnel"
      subtitle="Demo data is provided"
      :outline="false"
    >
      <div ref="wrapper">
        <VueFunnelGraph
          v-if="isFunnelVisible"
          :width="$refs.wrapper && $refs.wrapper.offsetWidth - 120"
          :height="height"
          :labels="labels"
          :values="values"
          :colors="colors"
          :direction="direction"
          :gradient-direction="gradientDirection"
          :animated="false"
          :display-percentage="true"
        />
      </div>
    </MoleculeCard>
    <div class="card-body">
      <OrganismClientsAnalytics />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { VueFunnelGraph } from 'vue-funnel-graph-js'
import MoleculeCard from '@/components/MoleculeCard'
import OrganismClientsAnalytics from '@/components/OrganismClientsAnalytics'

export default {
  title: vm => vm.$t('pages.profile.title'),

  components: {
    VueFunnelGraph,
    MoleculeCard,
    OrganismClientsAnalytics,
  },

  data: () => ({
    isFunnelVisible: false,
    resizeTimeout: null,
    labels: ['Total requests', 'Unique', 'Pinned', 'Success'],
    values: [632, 213, 47, 12],
    colors: ['#5261EC', '#BB65FF', '#F2A73B', '#EA236B'],
    direction: 'vertical',
    gradientDirection: 'vertical',
    height: 400,
  }),

  computed: mapGetters(['isStaff']),

  mounted () {
    this.isFunnelVisible = this.$refs.wrapper && this.$refs.wrapper.offsetWidth > 0
    window.addEventListener('resize', this.handleResize)
  },

  methods: {
    handleResize () {
      if (this.resizeTimeout) clearTimeout(this.resizeTimeout)
      this.resizeTimeout = setTimeout(() => {
        this.isFunnelVisible = false
        this.$nextTick(() => {
          this.isFunnelVisible = true
        })
      }, 200)
    }
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style lang="scss">
  .svg-funnel-js {
    $self: &;
    font-family: inherit;

    #{$self}__labels {
      #{$self}__label {
        .label {
          &__value {
            color: inherit;
            font-weight: 600;
            font-size: 1.25rem;
          }

          &__title {
            color: var(--tblr-muted);
            font-weight: 400;
            font-size: inherit;
          }

          &__percentage {
            color: var(--tblr-green);
          }
        }
      }
    }

    &.svg-funnel-js--vertical {
      #{$self}__label {
        padding-top: 20px;

        &:not(:first-child) {
          border-top-color: rgb(224, 224, 224);
          border-top-style: dashed;
          border-top-width: 2px;
        }
      }
    }
  }
</style>
